// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import { PlasmicFuture, DefaultFutureProps } from './plasmic/validaus_homepage/PlasmicFuture';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

import { FeatureContext } from '../utils/FeatureContext';

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface FutureProps extends Omit<DefaultFutureProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultFutureProps altogether and have
// total control over the props for your component.
interface FutureProps extends DefaultFutureProps {}

function Future_(props: FutureProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicFuture to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicFuture are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all FutureProps here, but feel free
  // to do whatever works for you.

  const [selFeature, setSelFeature] = React.useState(0);
  const value = { selFeature, setSelFeature };

  return (
    <FeatureContext.Provider value={value}>
      <PlasmicFuture root={{ ref }} {...props} />
    </FeatureContext.Provider>
  );
}

const Future = React.forwardRef(Future_);
export default Future;
