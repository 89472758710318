import imgFacts from '../assets/images/vd-screenshot-facts.png';
import imgHistogram from '../assets/images/vd-screenshot-histogram.png';
import imgReport from '../assets/images/vd-screenshot-report.png';
import imgApi from '../assets/images/vd-screenshot-api.png';
import imgSecurityi from '../assets/images/vd-screenshot-security.png';

interface FeatureMeta {
  title: string;
  description: string[];
  image: string;
}

export const features: FeatureMeta[] = [
  {
    title: 'No-code test management',
    description: [
      'Define your test criteria ("Facts") in Validaus, instead of in code.',
      'Enable your data experts (e.g. PMs, analysts) to manage tests.',
      'Modify test cases dynamically - no code changes required.',
    ],
    image: imgFacts,
  },
  {
    title: 'Firm-wide data consistency',
    description: [
      'Reuse Validaus tests across your stack - ETLs, back-ends, front-ends.',
      'Identify inconsistencies between your applications.',
      "Create a source of truth for your business domain's data.",
    ],
    image: imgHistogram,
  },
  {
    title: 'Automated reports & alerts',
    description: [
      'View test reports across your system, from dev to prod.',
      'Receive real-time alerts and root-cause analysis.',
      'Improve cross-team collaboration and reduce debugging time.',
    ],
    image: imgReport,
  },
  {
    title: 'Full SDLC integration',
    description: [
      'Use Validaus APIs for easy test-driven development.',
      'Integrate Validaus into your build pipeline to prevent production bugs.',
      'Monitor production, and catch errors in real-time.',
    ],
    image: imgApi,
  },
  {
    title: 'Secure & audited access',
    description: [
      'Control access to sensitive data, for compliance with HIPAA, GDPR, etc.',
      'Manages role-based / time-based access, and field-level redacting.',
      'Enable teams to securely view prod data, with full audit trails.',
    ],
    image: imgSecurityi,
  },
];
