// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type ProductIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function ProductIcon(props: ProductIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 25.994 25.994"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g fill={"currentColor"}>
        <path
          d={
            "M21.815 0H4.18a.853.853 0 00-.854.853V25.14c0 .472.381.854.853.854h13.083c.21 0 .413-.077.57-.218l4.55-4.072a.855.855 0 00.285-.636V.853A.853.853 0 0021.815 0zm-.853 20.59h-3.97v3.648l-.053.047H5.033V1.707h15.928V20.59z"
          }
        ></path>

        <path
          d={
            "M14.562 15.173h-4.337c-.247 0-.446.2-.446.446v4.337c0 .247.199.446.446.446h4.337c.246 0 .446-.2.446-.446V15.62a.446.446 0 00-.446-.447zm-.444 4.338h-3.446v-3.446h3.446v3.446zm.835-10.942v-.032a.394.394 0 00-.394-.394h-4.33a.394.394 0 00-.394.394v4.329c0 .219.177.395.394.395h4.331a.394.394 0 00.394-.395v-1.543l-.696.696c-.026.026-.062.039-.092.062v.393h-3.543V8.93h3.543v.426l.787-.787z"
          }
        ></path>

        <path
          d={
            "M15.764 8.313l-2.196 2.195-.84-.841a.579.579 0 10-.82.819l1.251 1.252a.58.58 0 00.82 0l2.605-2.606a.58.58 0 10-.82-.819zM7.305 4.159H18.69v1.189H7.305z"
          }
        ></path>
      </g>
    </svg>
  );
}

export default ProductIcon;
/* prettier-ignore-end */
