import React, { useEffect } from 'react';
import {
  useLocation,
  //, Switch
} from 'react-router-dom';
// import AppRoute from './utils/AppRoute';
// import ScrollReveal from './utils/ScrollReveal';
import ReactGA from 'react-ga';
import Home from './components/Home';

// Layouts
// import LayoutDefault from './layouts/LayoutDefault';

// Views
// import Home from './views/Home';

const GA_CODE = 'UA-191821748-2';

const isProd = () => process.env.NODE_ENV === 'production';

const trackPage = (page) => {
  if (!isProd()) {
    return;
  }
  ReactGA.set({ page });
  ReactGA.pageview(page);
};

const App = () => {
  // const childRef = useRef();
  const location = useLocation();

  // useEffect(() => {
  //   const page = location.pathname;
  //   document.body.classList.add('is-loaded');
  //   childRef.current.init();
  //   trackPage(page);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  useEffect(() => {
    // Initialize Google Analytics
    if (isProd()) {
      ReactGA.initialize(GA_CODE);
    }
    const page = location.pathname;
    trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Home />
    // <ScrollReveal
    //   ref={childRef}
    //   children={() => (
    //     <Switch>
    //       <AppRoute exact path='/' component={Home} layout={LayoutDefault} />
    //     </Switch>
    //   )}
    // />
  );
};

export default App;
