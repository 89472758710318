// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import {
  PlasmicTopSection,
  DefaultTopSectionProps,
} from './plasmic/simple_light_landing_page/PlasmicTopSection';
import { HTMLElementRefOf } from '@plasmicapp/react-web';
import TextLoop from 'react-text-loop';

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface TopSectionProps extends Omit<DefaultTopSectionProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultTopSectionProps altogether and have
// total control over the props for your component.
interface TopSectionProps extends DefaultTopSectionProps {}

function TopSection_(props: TopSectionProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicTopSection to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicTopSection are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all TopSectionProps here, but feel free
  // to do whatever works for you.

  const animatedWords = ['quality', 'accuracy', 'transparency', 'integrity', 'consistency'];

  return (
    <PlasmicTopSection
      root={{ ref }}
      heroRow22={{
        props: {
          children: (
            <TextLoop
              interval={2500}
              springConfig={{ stiffness: 150, damping: 50 }}
              fade={true}
              delay={0}
              adjustingSpeed={150}
              mask={false}
              noWrap={true}
            >
              {animatedWords.map((word) => (
                <span>{word}.</span>
              ))}
            </TextLoop>
          ),
        },
      }}
      {...props}
    />
  );
}

const TopSection = React.forwardRef(TopSection_);
export default TopSection;
