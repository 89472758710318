import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import { Link } from 'react-router-dom';

import '../../assets/scss/style.scss';
const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

const ContactForm = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [messageError, setMessageError] = React.useState('');
  const [formSubmitting, setFormSubmitting] = React.useState(false);
  const [formSuccessful, setFormSuccessful] = React.useState(false);

  const outerClasses = classNames(
    'cta section center-content-mobile',
    // 'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );

  const isValidEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const submitForm = () => {
    console.log('Form: ', name, email, message);

    let hasError = false;

    if (!name) {
      setNameError('Name is required');
      hasError = true;
    } else {
      setNameError('');
    }

    if (!email) {
      setEmailError('Email is required');
      hasError = true;
    } else if (!isValidEmail(email)) {
      setEmailError('Invalid email.');
      hasError = true;
    } else {
      setEmailError('');
    }

    if (!message) {
      setMessageError('Message is required');
      hasError = true;
    } else {
      setMessageError('');
    }

    if (hasError) {
      return;
    }

    console.log('form is good!');

    // Use your API endpoint URL you copied from the previous step
    const endpoint =
      'https://wdj5ud5ts2.execute-api.us-east-1.amazonaws.com/default/sendContactEmail';
    // We use JSON.stringify here so the data can be sent as a string via HTTP
    const body = JSON.stringify({
      senderName: name,
      senderEmail: email,
      message: message,
    });
    const requestOptions = {
      method: 'POST',
      body,
    };

    fetch(endpoint, requestOptions)
      .then((response) => {
        console.log('DEBUG ~ .then ~ response', response);
        if (!response.ok) throw new Error('Error in fetch');
        return response.json();
      })
      .then((response) => {
        console.log('DEBUG ~ .then ~ response', response);
        setFormSuccessful(true);
        setFormSubmitting(false);
      })
      .catch((error) => {
        console.log('DEBUG ~ sendEmail ~ error', error);
        setMessageError('An unexpected error occurred. Please try again later.');
        setFormSubmitting(false);
      });

    setFormSubmitting(true);
  };

  return (
    <section {...props} className={outerClasses}>
      <div className='container' style={{ maxWidth: '800px' }}>
        <div
          className={innerClasses}
          style={{
            flexDirection: 'column',
            background:
              'linear-gradient(90deg, #e7c36e 0%, #ba860e 100%) 0% 0% / 100% 100% repeat padding-box /* clip: text **/',
            borderRadius: 16,
          }}
        >
          {formSuccessful ? (
            <div className='cta-slogan' style={{ textAlign: 'center' }}>
              <h4 className='m-0' style={{ color: '#2a2d32', marginBottom: '20px' }}>
                Thank you!
              </h4>
              <h4 className='m-0' style={{ color: '#2a2d32' }}>
                We will get back to you soon.
              </h4>
            </div>
          ) : (
            <>
              <div className='cta-slogan' id='contact-us-form'>
                <h3 className='m-0' style={{ color: '#2a2d32' }}>
                  Contact Us
                </h3>
              </div>
              <div className='cta-action' style={{ width: '100%', textAlign: 'left' }}>
                <div className='flex-split'>
                  <div className='flex-left'>
                    <Input
                      id='name'
                      type='text'
                      label='Name'
                      // labelHidden
                      // hasIcon='right'
                      // placeholder='Your name'
                      className='input-margin'
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      hint={nameError}
                      status='error'
                    ></Input>
                  </div>
                  <div className='flex-right'>
                    <Input
                      id='email'
                      type='email'
                      label='Email'
                      // labelHidden
                      // hasIcon='right'
                      // placeholder='Your email'
                      className='input-margin'
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      hint={emailError}
                      status='error'
                    >
                      {/* <svg width='16' height='12' xmlns='http://www.w3.org/2000/svg'>
                <path d='M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z' fill='#376DF9' />
              </svg> */}
                    </Input>
                  </div>
                </div>
                <Input
                  id='message'
                  type='textarea'
                  label='Message'
                  // labelHidden
                  // hasIcon='right'
                  // placeholder='Leave us a message'
                  className='input-margin'
                  value={message}
                  onChange={(event) => {
                    setMessage(event.target.value);
                  }}
                  hint={messageError}
                  status='error'
                ></Input>
                <Link
                  to='#0'
                  className='button button-dark button-wide-mobile '
                  style={{ width: '100%', marginTop: '20px' }}
                  disabled={formSubmitting}
                  onClick={submitForm}
                >
                  {formSubmitting ? 'SENDING...' : 'SEND'}
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

ContactForm.propTypes = propTypes;
ContactForm.defaultProps = defaultProps;

export default ContactForm;
