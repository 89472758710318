// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react';
import {
  PlasmicSelectedFeature,
  DefaultSelectedFeatureProps,
} from './plasmic/validaus_homepage/PlasmicSelectedFeature';
import { HTMLElementRefOf } from '@plasmicapp/react-web';

import { FeatureContext } from '../utils/FeatureContext';
import { features } from '../utils/featureMetadata';

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface SelectedFeatureProps extends Omit<DefaultSelectedFeatureProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultSelectedFeatureProps altogether and have
// total control over the props for your component.
interface SelectedFeatureProps extends DefaultSelectedFeatureProps {}

function SelectedFeature_(props: SelectedFeatureProps, ref: HTMLElementRefOf<'div'>) {
  // Use PlasmicSelectedFeature to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicSelectedFeature are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all SelectedFeatureProps here, but feel free
  // to do whatever works for you.

  // return <PlasmicSelectedFeature root={{ ref }} {...props} />;

  const { selFeature } = React.useContext(FeatureContext);
  const selFeatureMeta = features[selFeature];

  return (
    <PlasmicSelectedFeature
      root={{ ref }}
      image={
        // eslint-disable-next-line
        <img
          // data-plasmic-name={"selFeatureScreenshot"}
          // data-plasmic-override={overrides.selFeatureScreenshot}
          alt={'sample-alt'}
          // className={classNames(defaultcss.img, sty.selFeatureScreenshot)}
          role={'img'}
          src={selFeatureMeta.image}
        />
      }
      description={selFeatureMeta.description.map((d) => `▸  ${d}`).join('\n')}
    />
  );
}

const SelectedFeature = React.forwardRef(SelectedFeature_);
export default SelectedFeature;
