// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type CodeIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function CodeIcon(props: CodeIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      version={"1.1"}
      x={"0"}
      y={"0"}
      viewBox={"0 0 511 511"}
      xmlSpace={"preserve"}
      height={"1em"}
      width={"1em"}
      style={{
        fill: "currentcolor",

        ...(style || {}),
      }}
      className={classNames("plasmic-default__svg", className)}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M455.5 40h-400C24.897 40 0 64.897 0 95.5v320C0 446.103 24.897 471 55.5 471h400c30.603 0 55.5-24.897 55.5-55.5v-320c0-30.603-24.897-55.5-55.5-55.5zM496 415.5c0 22.332-18.168 40.5-40.5 40.5h-400C33.168 456 15 437.832 15 415.5v-320C15 73.168 33.168 55 55.5 55h400c22.332 0 40.5 18.168 40.5 40.5v320z"
        }
      ></path>

      <path
        d={
          "M471.5 120h-432c-4.142 0-7.5 3.357-7.5 7.5s3.358 7.5 7.5 7.5h432c4.142 0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5zm-416-25a7.51 7.51 0 007.5-7.5 7.51 7.51 0 00-7.5-7.5 7.51 7.51 0 00-7.5 7.5 7.512 7.512 0 007.5 7.5zm64 0a7.51 7.51 0 007.5-7.5c0-1.971-.8-3.91-2.2-5.3a7.528 7.528 0 00-5.3-2.2 7.51 7.51 0 00-7.5 7.5 7.512 7.512 0 007.5 7.5zm-32 0a7.51 7.51 0 007.5-7.5 7.51 7.51 0 00-7.5-7.5c-1.97 0-3.91.8-5.3 2.2a7.526 7.526 0 00-2.2 5.3 7.512 7.512 0 007.5 7.5zm101.303 115.196a7.501 7.501 0 00-10.606 0l-80 80a7.5 7.5 0 000 10.607l80 80c1.464 1.464 3.384 2.196 5.303 2.196s3.839-.732 5.303-2.196a7.5 7.5 0 000-10.607L114.106 295.5l74.697-74.696a7.502 7.502 0 000-10.608zm144 0a7.501 7.501 0 00-10.606 10.607l74.697 74.696-74.697 74.696a7.5 7.5 0 0010.606 10.607l80-80a7.5 7.5 0 000-10.607l-80-79.999zm-42.74-9.745a7.504 7.504 0 00-9.611 4.485l-64 176a7.5 7.5 0 007.048 10.065 7.505 7.505 0 007.049-4.938l64-176a7.501 7.501 0 00-4.486-9.612z"
        }
      ></path>
    </svg>
  );
}

export default CodeIcon;
/* prettier-ignore-end */
